.infoText {
    color: var(--navy-blue);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: var(--fonts-default);
}

.seatchInput input {
    display: flex;
    width: 779px;
    height: 64px;
    padding: 8px 24px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--white);
    border: none;
    margin: 10px 0 0 0 ;
    color: var(--navy-blue);
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    font-family: var(--fonts-default);
}

.seatchInput input::placeholder{
    color: var(--grey);
    font-size: 20px;
    font-style: normal;
    line-height: normal;
}

.seatchInput input:focus-visible{
    outline: none;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
}

.arrowBtn {
    display: flex;
    width: 94px;
    height: 64px;
    padding: 4px 19px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: var(--white);
    cursor: pointer;
    transition: all 0.5s ease;
}

.seatchInput {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
}

.arrowBtn:hover{
    background: var(--light-orange);
}

.arrowBtn:hover svg path,
.arrowBtn svg:hover path {
    stroke: var(--white);
}


.arrowBtn:active{
    background: var(--orange);
}

.arrowBtn:active svg path,
.arrowBtn svg:active path {
    stroke: var(--white);
}

.populatText {
    color: var(--navy-blue);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: var(--fonts-default);
}

.populatText p{
    width: max-content;
}

.popularBox {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    flex-direction: row;
    gap: 24px;
    margin: 12px 0 0 0;
}

.popularCard p {
    color: var(--navy-blue);
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    font-family: var(--fonts-default);
}

.popularCard {
    display: inline-flex;
    height: 64px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--white);
    cursor: pointer;
    transition: all 0.5s ease;
}

.popularCard:hover{
    color: var(--white);
    background: var(--light-orange);
}

.popularCard:active{
    color: var(--white);
    background: var(--orange);
}

.popularBoxWords {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.disableWords {
    background: var(--grey);
    color: var(--white);
    pointer-events: none;
}

.disableArrow {
    background: var(--grey);
    pointer-events: none;
}

.disableArrow svg path {
    stroke: var(--white);
}


@media (max-device-width: 575.98px){

    .popularCard {
        width: 30%;
        box-sizing: border-box;
        display: flex;
        height: 44px;
        padding: 5.52px 16px;
        justify-content: center;
        align-items: center;
        gap: 6.9px;
    }

    .popularBox{
        flex-direction: column;
    }

    .infoText{
        font-size: 16px;
    }

    .populatText{
        font-size: 16px;
    }

    .popularCard p{
        font-size: 16px;
    }

    .seatchInput input {
        display: flex;
        width: auto;
        height: 44px;
        padding: 8px 24px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
    }

    .arrowBtn {
        display: flex;
        height: 44px;
        width: auto;
        padding: 8.894px 8.893px 8.894px 8.895px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    .arrowBtn svg {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        position: relative;
    }

    .seatchInput {
        justify-content: flex-start;
    }


}