.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url(../../public/bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homeContent{
  position: absolute;
  z-index: 999;
  top: 45%;
}

.bg{
    display: flex;
    background: url(../../public/Subtract.svg);
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 100vh;
    width: 90%;
    position: relative;
    bottom: 0;
    background-size: contain;
    top: 100px;
    z-index: 1;
}

.boxLogo img{
  height: 150px;
}

.conentBox{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 50px;
  
}

.boxSearch{
  width: 885px;
}

@media (max-device-width: 575.98px){
  .bg{
    width: 100%;
    background-size: cover;
  }

  .boxSearch{
    width: auto;
    padding: 16px;
  }

  .conentBox{
    gap:24px;
  }

  .homeContent{
    position: absolute;
    z-index: 999;
    top: auto;
    transform: translate(0%, 0%);
  }
}